<template>
  <base-section
    id="features"
    color="#0d2573"
    space="100"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <base-section-heading
          color="white"
          text-color="white"
          title="A EMPRESA"
        />
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="justify"
              dark
              color="white"
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          // icon: 'mdi-keyboard-outline',
          img: require('@/assets/icons/002-target.png'),
          title: 'MISSÃO',
          text: 'Garantir soluções seguras e especializadas nas áreas financeira, jurídica e administrativa, valorizando o seu patrimônio, a partir de uma gestão com eficácia, que trabalha em conjunto com o cliente e garante máxima confiabilidade, organização e comprometimento.',
        },
        {
          // icon: 'mdi-camera-outline',
          img: require('@/assets/icons/004-telescope.png'),
          title: 'VISÃO',
          text: 'Ser referência no mercado em gestão condominial, seguindo diretrizes que garantem a qualidade do processo.',
        },
        {
          // icon: 'mdi-pencil-outline',
          img: require('@/assets/icons/001-mission-accomplished.png'),
          title: 'PORQUE NOS ESCOLHER?',
          text: 'Oferecemos serviços integrados em incorporação imobiliária, administração de Condomínios, agregando a gestão administrativa com a de pessoas, contratos, jurídica, financeira e contábil.',
        },
      ],
    }),
  }
</script>
